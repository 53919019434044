<template>
  <div class="view-requests">
    <div class="view-requests__back">
      <img @click="$router.go(-1)" class="view-requests__back-icon" src="@/assets/svg/arrows/back.svg" alt="">
      <h1>{{ $t('back') }}</h1>
    </div>
    <div class="view-requests__action">
      <p class="view-requests__action-text" @click="orderDownloadApi" @mousemove="chatIconColor = true" @mouseleave="chatIconColor = false" v-if="getUser?.role === 'Admin' || getUser?.role === 'Moderator' || getUser?.role === 'Lawyer'">
        <img :src="chatIconColor ? require('@/assets/svg/icon-chat-red.svg') : require('@/assets/svg/icon-chat-grey.svg')" />
        {{ $t('generate-request') }}
      </p>
      <div class="view-requests__action-text" @mousemove="pdfIconColor = true" @mouseleave="pdfIconColor = false" @click="orderDownloadPdfApi" v-if="getUser?.role === 'Admin' || getUser?.role === 'Moderator' || getUser?.role === 'Lawyer'">
        <img :src="pdfIconColor ? require('@/assets/svg/icon-pdf-red.svg') : require('@/assets/svg/icon-pdf-grey.svg')" />
        {{ $t('download-request') }}
      </div>
      <ui-button class="view-requests__action-close" v-if="visibleCloseRequest" @click="showAlert = true">{{ $t('close-request') }}</ui-button>
      <div class="view-requests__action-icons" v-if="getUser?.role === 'Admin' || getUser?.role === 'Moderator' || getUser?.role === 'Lawyer'">
        <img src="@/assets/svg/icon-chat-grey.svg" @click="orderDownloadApi" />
<!--        <img @click="orderDownloadApi" src="@/assets/svg/icon-pdf-grey.svg" />-->
      </div>
    </div>
    <div v-if="getUser && getUser.id && getOrderItem && getOrderItem.id" class="view-requests__content">
<!--      <work-requests-card :data="getOrderItem" />-->
      <view-requests-body :data="getOrderItem" />
    </div>
    <ui-alert v-model="showAlert">
      <p class="ui-alert__title">{{ $t('close-request') }}?</p>
      <div class="ui-alert__action">
        <ui-button @click="closeOrder" color="error">{{ $t('closed-3') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import UiAlert from "@/components/ui/UiAlert";
import UiButton from "@/components/ui/UiButton";
// import WorkRequestsCard from "@/components/template/work-requests/WorkRequestsCard";
import ViewRequestsBody from "@/components/template/work-requests/ViewRequests/ViewRequestsBody";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WorkRequestsId",
  components: { ViewRequestsBody, UiAlert, UiButton  },

  data() {
    return {
      type: 'admin',
      showAlert: false,
      chatIconColor: false,
      pdfIconColor: false,
    }
  },

  computed: {
    ...mapGetters(['getOrderItem', 'getUser', 'getMapOrderZoom']),

    visibleCloseRequest() {
      return (
          (this.getUser?.id === this.getOrderItem?.moderatedBy && this.getUser?.role === 'Moderator' &&
          this.getOrderItem?.status === 'Accepted') || this.getUser?.role === 'Admin'
      )
    }
  },

  methods: {
    ...mapActions(['sendOrderId', 'sendOrderClose', 'orderDownload', 'orderDownloadPdf']),

    closeOrder() {
      this.sendOrderClose(this.getOrderItem.id)
      this.showAlert = false
    },

    orderDownloadApi () {
      this.orderDownload({id: this.getOrderItem.id}).then((res) => {
        Object.assign(document.createElement('a'), { href: res.fileSrc, target: '_blank' })
            .click();
        URL.revokeObjectURL(res.fileSrc);
      })
    },

    orderDownloadPdfApi () {
      this.orderDownloadPdf({id: this.getOrderItem.id, scale: this.getMapOrderZoom}).then((res) => {
        Object.assign(document.createElement('a'), { href: res.fileSrc, target: '_blank' })
            .click();
        URL.revokeObjectURL(res.fileSrc);
      })
    },
  },

  mounted() {
    const { id } = this.$route.params
    this.sendOrderId(id)
  }
}
</script>

<style lang="scss" scoped>
.view-requests {
  min-height: 100%;
  background: #F5F5F5;
  padding: 93px 50px 0 50px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 768px) {
    gap: 15px;
    padding: 104px 20px 50px 20px;
    overflow: auto;
    max-height: 100%;
  }

  @media(max-width: 1250px) {
    padding: 100px 20px 0 20px;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: #F5F5F5;
    z-index: 5;

    @media (max-width: 768px) {
      position: relative;
      height: auto;
    }

    &-icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #343432;
    }
  }

  &__content {
    display: flex;
    justify-content: start;
    gap: 21px;
    height: 100%;

    @media (max-width: 1400px) {
      gap: 20px;
    }

    @media (max-width: 768px) {
      padding-top: 0;
    }

    .card {
      min-width: 320px;
      @media (max-width: 1250px) {
        display: none;
      }
    }

  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    @media (max-width: 768px) {
      justify-content: space-between;
    }

    &-text {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #9A9A9A;
      white-space: nowrap;
      cursor: pointer;

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        color: #E21F1F;
      }
    }

    &-close {
      max-width: 180px !important;

      @media (max-width: 768px) {
        height: 30px !important;
      }
    }

    &-icons {
      display: none;
      gap: 20px;

      @media (max-width: 768px) {
        display: flex;
      }
    }
  }
}
</style>
